/******************************************/
/* Base
/******************************************/

$base-background-color: #FFFFFF;
$main-font: 'Roboto','Arial', sans-serif;
$main-color: #494949;
$accent-font: 'Roboto','Arial', sans-serif;

/******************************************/
/* Form
/******************************************/
$form-color: #F1B32A;
$form-label-clr: #054D96;
$form-label-weight: 700;
$form-element-color: #979797;

$dark-primary-text: #494949;
$light-primary-text: white;

/******************************************/
/* Header
/******************************************/

$header-bg: linear-gradient(99deg, #014A94, #387CB1);;
$header-clr: #FFFFFF;

/******************************************/
/* Button
/******************************************/

$main-btn-bg: #197E56;
$main-btn-color: #FFFFFF;
$main-btn-radius: 30px;
$main-btn-weight: bold;
$main-btn-txt-transform: capitalize;

/******************************************/
/* Slider
/******************************************/

$slider-main: #054D96;
$slider-accent: #F1B32A;
$slider-arrows: #054D96;

/******************************************/
/* FAQ
/******************************************/

$questions-arrow: #054D96;

/******************************************/
/* Footer
/******************************************/

$footer-bg: #000;
$footer-color: #FFFFFF;
$footer-questions-color: #FFFFFF;
$footer-shadow: 0 1px 12px rgba(#000, .08);

/******************************************/
/* Steps
/******************************************/

$steps-bg: #054D96;
$steps-main-color: #054D96;
$steps-active-color: #FFFFFF;

/******************************************/
/* Secure
/******************************************/

$secure-border-bgd: #F1B32A;
$secure-rds: 9px;
$secure-clr: #054D96;
$secure-font: $main-font;
$secure-weight: 700;

$secure-subtitle-font: $main-font;
$secure-subtitle-weight: 900;

/******************************************/
/* Support
/******************************************/

$support-icons-color: #054D96;

/******************************************/
/* Traveler
/******************************************/

$traveler-bg: #EAF3F9;
$traveler-clr: #054D96;

/******************************************/
/* Product
/******************************************/

$product-bg: #054D96;
$product-clr: #FFFFFF;
$product-weight: lighter;

/******************************************/
/* Agreement
/******************************************/

$agreement-clr: #535050;
$agreement-bg: #FAFAFA;

/******************************************/
/* Summary
/******************************************/

$summary-border-radius: 5px;
$summary-bg: linear-gradient(135deg,rgba(56, 124, 177, 0.75) 0,#014A94 100%);;
$summary-clr: rgba(255, 255, 255, 0.6);;
$summary-separator-width: 1px;
$summary-separator-color: #D8D8D8;
$summary-pill-bgd: #444444;
$summary-box-shadow:  0 2px 50px rgba(#000, .15);
$summary-title-color: #fdfad6;
// $secondary-btn: #041a55;
